<template>
  <div class="center">
    <!--列表label-->
    <head-layout
      head-title="法人组织管理"
      :head-btn-options="headBtnOptions"
      @head-remove="handleDelete"
      @head-add="headAdd"
    ></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :head-title="this.$t('cip.plat.sync.dept.title.indexHeadTitle')"
      :table-options="option"
      :table-data="data"
      :table-loading="loading"
      :data-total="page.total"
      :grid-row-btn="gridRowBtn"
      :page="page"
      @gird-handle-select-click="selectionChange"
      @grid-row-detail-click="rowView"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @tree-load="treeLoad"
      @row-edit="rowEdit"
      @row-del="rowDel"
    >
    </grid-layout>
  </div>
</template>

<script>
import { getList, getLazyList, remove } from "@/api/sync/corpnOrg";
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  components: { HeadLayout, GridLayout },
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 1000,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        lazy: true,
        tree: true,
        rowKey: "code", //唯一标识，code是主键
        index: true,
        indexLabel: "序号",
        menuFixed: "right", //操作列冻结
        menuWidth: 120,
        // linklabel: "title",
        column: [
          {
            label: "名称",
            prop: "name",
            align: "left",
            overHidden: true,
            width: 380,
          },
          {
            label: "编码",
            prop: "code",
            align: "left",
            overHidden: true,
            width: 150,
          },
          {
            label: "类型名称",
            prop: "typeName",
            // dicData: [
            //   { label: "法人组织", value: "1" },
            //   { label: "虚拟法人", value: "2" },
            // ],
            // props: {
            //   label: "label",
            //   value: "value",
            // },
            type: "select",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "简称",
            prop: "shortName",
            align: "center",
            overHidden: true,
          },
          {
            label: "境内外",
            prop: "projectAreaVals",
            dicData: [
              { label: "境内", value: "1" },
              { label: "境外", value: "2" },
            ],
            props: {
              label: "label",
              value: "value",
            },
            type: "select",
            align: "center",
            overHidden: true,
            width: 100,
          },
          {
            label: "排序",
            prop: "sortOrder",
            align: "center",
            overHidden: true,
            width: 100,
          },
          {
            label: "分发类型",
            prop: "disType",
            type: "select",
            align: "center",
            overHidden: true,
            width: 100,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=dis_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          // {
          //   label: "状态",
          //   prop: "status",
          //   dicData: [
          //     { label: "停用", value: "0" },
          //     { label: "启用", value: "1" },
          //   ],
          //   props: {
          //     label: "label",
          //     value: "value",
          //   },
          //   type: "select",
          //   dataType: "number",
          //   align: "center",
          //   width: 100,
          //   // dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=dept_status",
          //   // props: {
          //   //   label: "dictValue",
          //   //   value: "dictKey",
          //   // },
          // },
        ],
      },
      data: [],
      pcode: "",
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    // permissionList() {
    //   return {
    //     addBtn: this.vaildData(this.permission.personalSharing_add, false),
    //     delBtn: this.vaildData(this.permission.personalSharing_delete, false),
    //     editBtn: this.vaildData(this.permission.personalSharing_edit, false),
    //   };
    // },
    code() {
      let code = [];
      this.selectionList.forEach((ele) => {
        code.push(ele.code);
      });
      return code.join(",");
    },
    searchColumns() {
      return [
        {
          prop: "name",
          span: 4,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "名称",
        },
        {
          prop: "project_area_vals",
          span: 4,
          type: "select",
          clearable: true,
          dicData: [
            { label: "境内", value: "1" },
            { label: "境外", value: "2" },
          ],
          props: {
            label: "label",
            value: "value",
          },
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "境内外",
        },
        {
          prop: "type_name",
          span: 4,
          type: "select",
          clearable: true,
          // dataType: "number",
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "类型名称",
          dicData: [
            { label: "法人组织", value: "法人组织" },
            { label: "虚拟法人", value: "虚拟法人" },
            { label: "项目公司", value: "项目公司" },
            { label: "法人公司", value: "法人公司" },
            { label: "法人(分公司)", value: "法人(分公司)" },
            { label: "法人(参股公司)", value: "法人(参股公司)" },
          ],
          props: {
            label: "label",
            value: "value",
          },
        },
      ];
    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.addBtn"), //新增
        emit: "head-add",
        btnOptType: "add",
      });
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.delBtn"), //删除
        emit: "head-remove",
        btnOptType: "remove",
      });
      return buttonBtn;
    },
    // 行按钮添加
    gridRowBtn() {
      let buttonBtn = [];
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.editBtn"), //编辑
        emit: "row-edit",
      });
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.delBtn"), //删除
        emit: "row-del",
      });
      return buttonBtn;
    },
  },
  methods: {
    // 列表查询
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 查询重置
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    headAdd() {
      this.$router.push({
        path: `/system/sync/corpnOrg/add`,
        query: {
          type: "add",
        },
      });
    },
    findchildren(arr, code) {
      for (let i = 0; arr.length > i; ++i) {
        let it = arr[i];
        if (code == it.code) {
          return it;
        }

        if (undefined != it.children) {
          let itt = this.findchildren(it.children, code);
          if (undefined != itt) {
            return itt;
          }
        }
      }
    },
    rowEdit(row) {
      // console.log(row, this.data, "nnnnnnnnn");
      // let data = this.data.filter((value, index) => {
      //   return value.code == row.code;
      // });
      let data = this.findchildren(this.data, row.code);
      // console.log(row, data, "zzzzzzzzzzzzzzzzz");
      // let tatacc = {};
      // if (undefined != data && data.length > 0) {
      //   tatacc = data[0];
      // }
      // console.log(data, "mmmmmmmmmmm");
      // console.log(tatacc, "xxxxxxxxxxxxxxxx");
      this.$router.push({
        path: `/system/sync/corpnOrg/edit`,
        query: {
          code: row.code,
          data: data,
          type: "edit",
        },
      });
    },
    rowView(row) {
      this.$router.push({
        path: `/system/sync/corpnOrg/view`,
        query: {
          code: row.code,
          type: "view",
        },
      });
    },
    // rowDel(row) {
    //   this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
    //     confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
    //     cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
    //     type: "warning",
    //   })
    //     .then(() => {
    //       remove(row.code);
    //     })
    //     .then(() => {
    //       this.$message({
    //         type: "success",
    //         message: this.$t("cip.cmn.msg.success.operateSuccess"),
    //       });
    //       this.onLoad(this.page);
    //     });
    // },
    rowDel(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        remove(row.code).then((res) => {
          if (res.data.code == 200) {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          }
        });
        // })
        // .then(() => {
        // this.$message({
        //   type: "success",
        //   message: this.$t("cip.cmn.msg.success.operateSuccess"),
        // });
        // this.onLoad(this.page);
      });
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.chooseAtLeastOne"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        remove(this.code).then((res) => {
          if (res.data.code == 200) {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          }
          // else if (res.data.code == 400) {
          //   this.onLoad(this.page);
          //   this.$message({
          //     type: "warning",
          //     message: "含有子项，无法删除",
          //   });
          // }
        });
      });
    },
    onLoad(page, params = {}) {
      // console.log(page, "ccccccg");
      this.page = page;
      this.loading = true;

      if (params.name || params.project_area_vals || params.type_name) {
        try {
          getList(
            page.currentPage,
            page.pageSize,
            params
            // Object.assign(params, this.$refs.gridHeadLayout.searchForm)
          ).then((res) => {
            console.log(res, "fgfgggg");
            const data = res.data.data;
            this.loading = false;
            // this.page.total = data.total;
            this.data = data.records;

            this.$refs.gridLayOut.grid.refreshTable(); //解决表格错位
            this.selectionList = [];
          });
        } catch (error) {
          this.loading = false;
          this.selectionList = [];
        }
      } else {
        getLazyList(
          page.currentPage,
          page.pageSize,
          this.pcode
          // Object.assign(this.$refs.gridHeadLayout.searchForm)
        ).then((res) => {
          console.log(res, "aaaaaa");
          const data = res.data.data;
          // this.page.total = data.total;
          this.data = data.records;
          this.loading = false;
          this.$refs.gridLayOut.grid.refreshTable();
          this.selectionList = [];
        });
      }
    },
    treeLoad(tree, treeNode, resolve) {
      const pcode = tree.code;
      // console.log(tree, "treeeeeeeeee");
      getLazyList(1, 1000, pcode).then((res) => {
        // console.log(res, "nnnnnnnnnn");
        resolve(res.data.data.records);
      });
    },
  },
  // 初始化数据
  mounted() {
    this.onLoad(this.page, {});
  },
};
</script>
